import { useI18n } from '#imports'
import { computed } from 'vue'

/**
 * Returns the language-specific search path based on current locale.
 * Falls back to Swedish if locale is unknown.
 */
export const useSearchPath = () => {
  const { locale } = useI18n()
  //   const config = useRuntimeConfig()

  const searchPath = computed(() => {
    switch (locale.value) {
      case 'sv':
        return '/sok'
      case 'uk':
        return '/search'
      case 'en':
        return '/search'
      default:
        return '/sv'
    }
  })

  return searchPath.value
}
