
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_9386nK97SeAGzwMFCwe64iCyvR8hVd3p79k2CoZbZ5C6EMeta } from "/opt/render/project/src/apps/refresh/pages/[...slug].vue?macro=true";
import { default as rejectP0ylpPqVsmNcKW_45EB7TQJuqQD3xnivEDrpil9Fyc4f4Meta } from "/opt/render/project/src/apps/refresh/pages/swish/reject.vue?macro=true";
import { default as successW4sFEo8IgV_Z1u3qt0U_45TtYflZ0qnQd580w3O_45JZnwkMeta } from "/opt/render/project/src/apps/refresh/pages/swish/success.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/render/project/src/apps/refresh/pages/[...slug].vue")
  },
  {
    name: "swish-reject",
    path: "/swish/reject",
    component: () => import("/opt/render/project/src/apps/refresh/pages/swish/reject.vue")
  },
  {
    name: "swish-success",
    path: "/swish/success",
    component: () => import("/opt/render/project/src/apps/refresh/pages/swish/success.vue")
  }
]